---
title: "Configure ElasticPath(Moltin)"
---

```
(NOTE: You can return to this Configuration panel at any time (as an admin) to modify your settings or subscription settings)
```

<br/>

<p>
<Anchor href="https://www.elasticpath.com/">Elastic Path</Anchor><span>&nbsp;, formerly Moltin, can be connected to Zendesk as a Commerce Provider through the agnoStack plugin and supports using external Shipping and Payment Providers.</span>
</p>

<br/>

<center>
  <img class="border perspective sidebar" src="https://agnostack.com/images/elasticpath-configuration-screenshot.png" data-canonical-src="https://agnostack.com/images/elasticpath-configuration-screenshot.png" width="300"/>
</center>

<br/>

## Elastic Path Dashboard

Inside the agnoStack app you will be prompted to enter your `Client ID` and `Client Secret`. Both of these fields can be found within the Moltin Admin Dashboard screen upon login:

<br/>

<center>
  <img class="border" src="https://agnostack.com/images/elasticpath-keys-screenshot.png" data-canonical-src="https://agnostack.com/images/elasticpath-keys-screenshot.png" width="600" />
</center>

<br/>

## Configure Payment and Shipping Providers

If you don't see your current Payment Gateway listed, you can still activate and use the app by suggesting a new provider (below/left).

<br/>

<div class="image-group">
  <img class="border" alt="Suggest Payment Provider" title="Suggest Payment Provider" src="https://agnostack.com/images/configuration-suggest-payment-screenshot.png" data-canonical-src="https://agnostack.com/images/configuration-suggest-payment-screenshot.png" />
  <img class="border" alt="Select Shipping Provider" title="Select Shipping Provider" src="https://agnostack.com/images/configuration-shipping-screenshot.png" data-canonical-src="https://agnostack.com/images/configuration-shipping-screenshot.png" />
</div>

<br/>

We regularly add new Providers to the application and continually reprioritizing our Roadmap based on customer input. If you use a Gateway or Shipping Provider that you don't see listed, just let us know and we'll take that info back to the team ASAP!

<br/>
<br/>

<div class="website">
Once you've completed entering your Provider configuration data, continue on to <Anchor href="https://agnostack.com/faqs/setup-onboarding/select-a-subscription">Select a Subscription</Anchor>.
</div>
