---
title: "Configure BigCommerce"
---

```
(NOTE: You can return to this Configuration panel at any time (as an admin) to modify your settings or subscription settings)
```

<br/>

<p>
<Anchor href="https://www.bigcommerce.com">BigCommerce</Anchor><span>&nbsp;can be connected to Zendesk as a Commerce Provider through the agnoStack plugin and supports using a built-in Shipping and Payment Provider, making use of the configuration you've already set up in BigCommerce.</span>
</p>

<br/>

<center>
  <img
    class="border perspective sidebar"
    src="https://agnostack.com/images/bigcommerce-configuration-screenshot-listing.png"
    data-canonical-src="https://agnostack.com/images/bigcommerce-configuration-screenshot-listing.png"
    width="300"
  />
</center>

<br/>
<br/>

## Channel ID(s) (Optional)

<p>
Channel ID(s) is optional, but if you are running multiple channels within your BigCommerce instance, you can filter orders by channel origin by entering in Channel ID(s).
</p>

<br/>

<p>
You can obtain Channel ID(s) by navigating to <b>Channel Manager</b> from your BigCommerce store dashboard. Your default channel will have a Channel ID of "<b>1</b>". 
</p>

<br/>

<p>
Obtain the Channel ID for other channels by clicking on the three horizontal dots to the right of a channel and then selecting <b>Advanced Settings</b>.
</p>

<br/>
<br/>

<center>
  <img
    class="border perspective sidebar"
    width="500"
    src="https://agnostack.com/images/bigcommerce-configuration-channel-ids-screenshot.png"
    data-canonical-src="https://agnostack.com/images/bigcommerce-configuration-channel-ids-screenshot.png"
  />
</center>

<br/>

```
(NOTE: If you need to configure across multiple channels at once you can enter All, *, or a comma-separated list of store codes into the input field. If nothing is entered in the field it will automatically point to all of your channels.)
```
