---
title: "Configure WooCommerce"
---

```
(NOTE: You can return to this Configuration panel at any time (as an admin) to modify your settings or subscription settings)
```

<br/>

<p>
<Anchor href="https://woocommerce.com/">WooCommerce</Anchor><span>&nbsp;can be connected to Zendesk as a Commerce Provider through the agnoStack plugin and supports using a built-in Shipping Provider, making use of the shipping configuration you've already set up in WooCommerce. It currently supports up to our Premium subscription tier.</span>
</p>

<br/>

<center>
  <img
    class="border perspective sidebar"
    src="https://agnostack.com/images/woocommerce-configuration-screenshot.png"
    data-canonical-src="https://agnostack.com/images/woocommerce-configuration-screenshot.png"
    width="300"
  />
</center>

<br/>
<br/>

## WordPress Dashboard

<p>
If you already have API credentials mentioned below, you may enter them in the agnoStack configuration screen. Otherwise, you can obtain these values by performing the following steps:
</p>

<br/>

<p>
From your WordPress admin panel and inside your WooCommerce section, navigate to the <i>Settings</i> screen by selecting <b>Settings</b>. 
</p>

<br/>

<p>
Once on the <i>Settings</i> screen, click on the __Advanced__ tab located in the top right. Select __REST API__ and then click on __Add key__.
</p>

<br/>

<center>
  <img class="border" width="500" src="https://agnostack.com/images/woocommerce-create-new-api-client-screenshot.png" data-canonical-src="https://agnostack.com/images/woocommerce-create-new-api-client-screenshot.png" />
</center>

<br/>

<p>
Enter a __Description__ and then select the __User__ you would like to generate a key for in the dropdown. Select __Read/Write__ for __Permissions__. Click <b>Generate API key</b> once completed.
</p>

<br/>

<center>
  <img class="border" width="500" src="https://agnostack.com/images/woocommerce-api-configuration-screenshot.png" data-canonical-src="https://agnostack.com/images/woocommerce-api-configuration-screenshot.png" />
</center>

<br/>

<p>
You will be presented with your credentials.  You will need to obtain <b>Consumer key</b> and <b>Consumer Secret</b>. Save these credentials as this info will not be available once you have moved on from this page. Use these credentials to sign in to the agnoStack plugin.
</p>

<br/>

<center>
  <img class="border" width="500" src="https://agnostack.com/images/woocommerce-keys-screenshot.png" data-canonical-src="https://agnostack.com/images/woocommerce-keys-screenshot.png" />
</center>

<br/>
<br/>

We regularly add new Providers to the application and continually reprioritizing our Roadmap based on customer input. If you use a Gateway that you don't see listed, just let us know and we'll take that info back to the team ASAP!

<br/>
<br/>

<div class="website">
Once you've completed entering your Provider configuration data, continue on to <Anchor href="https://agnostack.com/faqs/setup-onboarding/select-a-subscription">Select a Subscription</Anchor>.
</div>
