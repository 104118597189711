---
title: "Manage Permissions"
---

Depending on your selected Providers and agnoStack subscription plan, you may be presented with an additional configuration step to restrict access to certain features within agnoStack for any individual users within your Zendesk account.

<br/>

## Agent Level Restrictions

<div class="columns">
  <div>
    <p>
      By default, all agents will still have access to any features enabled for your selected Subscription Tier unless any permission restrictions are explicitly applied.
    </p>
    <br/>
    <p>
      You can restrict access to all agents to a set of features by simply toggling a permission to disabled. You can also restrict only specific agent's permissions by toggling a permission off and then selecting the restricted agents from the dropdown.
    </p>
    <br/>
    <pre>
      <code>
        NOTE: there are no actions required on this screen, if you do not want to explicity limit access to certain agnoStack features.
      </code>
    </pre>
    <br/>
  </div>
  <div class="third right">
    <img	
      class="border perspective sidebar"
      alt="Permissions Management"
      title="Permissions Management"
      src="https://agnostack.com/images/features-permissions-management.png"
      data-canonical-src="https://agnostack.com/images/features-permissions-management.png"
    />	
  </div>
</div>


<p>
  <b>Permission Management Sets</b>
  <br />Depending on the available features for your selected Subscription, the following features can be configured in this section.
</p>
<br/>
<ol>
  <li>
    <b>Macro Permissions</b><span>&nbsp;prevents agents from using the agnoStack Macro Snippets tool as well as Advanced Macros functionality. This removes the ability to insert details from your Commerce, Payment and Shipping providers directly into a Zendesk ticket, as well as creating and managing templated customer responses via our Advanced Macros functionality.</span>
  </li>
  <li>
    <b>Order Permissions</b><span>&nbsp;prevents agents from performing any modifications to existing orders. This includes preventing modifying existing line items as well as adding new items to an existing order.</span>
  </li>
  <li>
    <b>Refund Permissions</b><span>&nbsp;prevents agents from being able to process order refunds. This includes all types of refunds (full, partial, item-level as well as custom refund amounts).</span>
  </li>
</ol>

<br/>
<p>
  Continue through configuration by clicking <b>Review</b> and ensure you click <b>Activate</b> on the final page to save and apply your changes across your account.
</p>
