---
title: "Select A Subscription"
---

After configuring your Commerce Provider and any additional required Shipping and Payment Providers, you'll be brought to the Configure Subscription screen.

<br/>

<div class="columns">
    <div>
        <p>
        Depending on the Commerce Provider selected, you will be prompted with a set of available Subscription Tiers.
        You can find additional details on features available for each Subscription Tier on our <Anchor href="https://agnostack.com/pricing#tiers">Pricing Page</Anchor>.
        </p>
        <br/>
        <p>
        We continue to add additional features to all Tiers on a regular basis. We're also working to enable our Professional and Enterprise plans for additional Commerce Providers.
        If you don't see a tier available for your specific platform, please reach out to <a target="_blank" href="mailto:support@agnostack.com">support@agnostack.com</a> and we'd be glad to share any additional details on our Roadmap as to if/when it may become available.
        </p>
        <br/>
        <p>
        Once you've selected your Subscription Tier, you will be prompted to enter in a valid Credit Card to be charged on a monthly basis based on the number of Zendesk agents with the plugin available within their account.
        </p>
        <br/>
        <p>
        Several of our present Subscription Tiers offer a `free trial` and we encourage you to give any of our Subscription Tiers a trial run any time!
        </p>
        <br/>
        <pre>
        <code>
        (NOTE: We reserve the right at any time to change functionality available within a given Tier or what Tiers are available for each Provider.)
        </code>
        </pre>
        <p>
        <br/>
        <br/>
        If you want to learn more about on our our available Tiers any time without activating it, we're be glad to <Anchor href="https://agnostack.com/book-a-meeting">set up a demo</Anchor> to take you through.
        </p>
        <p class="website">
        <br/>
        <br/>
        Once you've completed selecting a subscription, continue on to <Anchor href="https://agnostack.com/faqs/setup-onboarding/select-add-ons">Select Add-Ons</Anchor>.
        </p>
    </div>
    <div class="third right website">
        <img src="https://agnostack.com/images/configuration-subscription-screenshot.png" data-canonical-src="https://agnostack.com/images/configuration-subscription-screenshot.png" />
    </div>
</div>
