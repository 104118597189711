---
title: "Configure Providers"
---

<p>
  This guide will help guide you through the configuration process.
</p>

<br/>

<p>
  <b>To get started, select your Commerce platform on the right.</b>
</p>

<p>
  If you're having any issues, we're available any time at: <a target="_blank" href="mailto:support@agnostack.com">support@agnostack.com</a>!
</p>

<br/>

<p>
Supercharge your customer experience. Provide customer service agents with real-time commerce data and robust sales tools to make them more efficient and proactive - leading to happier customers AND increased sales!
</p>

<br/>

<p>
<b>Multiple Configuration Support</b>
</p>

<p>
agnoStack now supports the ability to connect multiple platforms and/or multiple storefronts into a single, unified view of your customer's orders!  This feature is available on our Enterprise subscription tier.
</p>

<br/>

<p>
Our new "Multi-Config" feature allows you to configure up to five integrations and instantly search for orders and customers across them all at once. The Orders List and Customers List view will now display data across all configurations and contain a simple icon to help identify which platform each record relates to.
</p>

<br/>
<br/>

```
(NOTE: You can return to the Configure/Manage Your Account Screen at any time as and admin to modify your settings or subscription settings)
```
