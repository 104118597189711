---
title: "Configure BigCommerce"
---

```
(NOTE: You can return to this Configuration panel at any time (as an admin) to modify your settings or subscription settings)
```

<br/>

<p>
<Anchor href="https://www.bigcommerce.com">BigCommerce</Anchor><span>&nbsp;can be connected to Zendesk as a Commerce Provider through the agnoStack plugin and supports using a built-in Shipping and Payment Provider, making use of the configuration you've already set up in BigCommerce.</span>
</p>

<br/>

<center>
  <img
    class="border perspective sidebar"
    src="https://agnostack.com/images/bigcommerce-configuration-screenshot.png"
    data-canonical-src="https://agnostack.com/images/bigcommerce-configuration-screenshot.png"
    width="300"
  />
</center>

<br/>
<br/>

## BigCommerce Dashboard

<p>
If you already have API credentials mentioned below, you may enter them in the agnoStack configuration screen. Otherwise, you can obtain these values by performing the following steps:
</p>

<p>
From your BigCommerce store dashboard, navigate to the API accounts page by selecting <b>Advanced Settings</b> > <b>API Accounts</b>.
</p>

<p>
Once on the <i>Store API Accounts</i> screen, click on <b>Create API Account</b> and choose the <b>Create V2/V3 API Token</b> option. NOTE: You may give your API account any name you wish.
</p>

<br/>
<br/>

## API Path

<p>
Copy the value of <i>API Path</i> from the screen below to use in the agnoStack plugin configuration screen.
</p>

<br/>

<center>
  <img
    class="border"
    width="500"
    src="https://agnostack.com/images/bigcommerce-configuration-api-path-screenshot.png"
    data-canonical-src="https://agnostack.com/images/bigcommerce-configuration-api-path-screenshot.png"
  />
</center>

<br />

```
(NOTE: if you already have API credentials which you wish to reuse, the value for API Path is the same across all API's in your store.)
```

<br/>
<br/>

## Client ID and Access Token

<p>
In order for the agnoStack plugin to gain access to your API, you will need to configure the proper permissions in the <i>OAuth Scopes</i> section.
</p>

<br/>

<p>
While setting up your <i>API</i>, ensure the following scopes have proper permission <b>(other scopes can be set to your discretion)</b>:
</p>

<br/>

<center>
  <img
    class="border"
    width="500"
    src="https://agnostack.com/images/bigcommerce-configuration-scopes-screenshot.png"
    data-canonical-src="https://agnostack.com/images/bigcommerce-configuration-scopes-screenshot.png"
  />
</center>

```
(NOTE: after activation, if your plugin displays a message containing "fail-permissions-bc", it likely means your API OAuth Scopes were configured incorrectly.)
```

<br/>
<br/>

<p>
Save your new API Account, and you will be presented with your credentials.  You will need to obtain both the <b>Client ID</b> and <b>Access Token</b>.
</p>

<br/>

<center>
  <img
    class="border"
    width="600"
    src="https://agnostack.com/images/bigcommerce-configuration-api-credentials-screenshot.png"
    data-canonical-src="https://agnostack.com/images/bigcommerce-configuration-api-credentials-screenshot.png"
  />
</center>

<br/>
<br/>

## Channel ID(s) (Optional)

<p>
Channel ID(s) is optional, but if you are running multiple channels within your BigCommerce instance, you can filter orders by channel origin by entering in Channel ID(s).
</p>

<br/>

<p>
You can obtain Channel ID(s) by navigating to <b>Channel Manager</b> from your BigCommerce store dashboard. Your default channel will have a Channel ID of "<b>1</b>". 
</p>

<br/>

<p>
Obtain the Channel ID for other channels by clicking on the three horizontal dots to the right of a channel and then selecting <b>Advanced Settings</b>.
</p>

<br/>
<br/>

<center>
  <img
    class="border"
    width="500"
    src="https://agnostack.com/images/bigcommerce-configuration-channel-ids-screenshot.png"
    data-canonical-src="https://agnostack.com/images/bigcommerce-configuration-channel-ids-screenshot.png"
  />
</center>

<br/>

```
(NOTE: If you need to configure across multiple channels at once you can enter All, *, or a comma-separated list of store codes into the input field. If nothing is entered in the field it will automatically point to all of your channels.)
```
