---
title: "Configure Shopify"
---

```
(NOTE: You can return to this Configuration panel at any time (as an admin) to modify your settings or subscription settings)
```

<br/>

<p>
  <Anchor href="https://www.shopify.com/">Shopify</Anchor><span>&nbsp;can be connected to Zendesk as a Commerce Provider through the agnoStack plugin and supports using a built-in Shipping and Payment Provider, making use of the  configuration you've already set up in Shopify.</span>
</p>

<br/>

<center>
  <img
    class="border perspective sidebar"
    src="https://agnostack.com/images/shopify-configuration-screenshot.png"
    data-canonical-src="https://agnostack.com/images/shopify-configuration-screenshot.png"
    width="300"
  />
</center>

<br/>
<br/>

## SHOPIFY DASHBOARD

<br/>

<p>
Your <b>Store Name</b> is the same as your Shopify subdomain. For example, if your Shopify Admin URL is <i>mystorefront.myshopify.com</i>, the value for Store Name should be entered as <i>mystorefront</i> (the Shopify Store Name will be the section of the URL after "https://" and before ".myshopify.com").
</p>

<br/>

<p>
If you already have the API credentials mentioned below, you can enter them directly within the agnoStack configuration screen. If you need to obtain the credentials, you can access these values by performing the following steps:
</p>

<br/>

<p>
From your Shopify store dashboard, navigate to the <i>Apps</i> page by selecting <b>Apps</b> from your left-hand sidebar.
</p>

<br/>

<p>
At the bottom of the <i>Apps</i> page, click on <b>Develop apps for your store</b>.
</p>

<br/>

<center>
  <img
    class="border"
    width="600"
    src="https://agnostack.com/images/shopify-create-private-app-screenshot.png"
    data-canonical-src="https://agnostack.com/images/shopify-create-private-app-screenshot.png"
  />
</center>

<br/>

<p>
If Private apps are not enabled, click on <b>Enable private app development</b>.
</p>

<br/>

<center>
  <img
    class="border"
    width="600"
    src="https://agnostack.com/images/shopify-enable-private-app-screenshot.png"
    data-canonical-src="https://agnostack.com/images/shopify-enable-private-app-screenshot.png"
  />
</center>

<br/>

<p>
Check all boxes on the following page and then click on <b>Allow custom app development</b>.
</p>

<br/>

<center>
  <img
    class="border"
    width="600"
    src="https://agnostack.com/images/shopify-enable-private-app-confirmation-screenshot.png"
    data-canonical-src="https://agnostack.com/images/shopify-enable-private-app-confirmation-screenshot.png"
  />
</center>

<br/>

<p>
Click on <b>Create an app</b>.
</p>

<br/>

<center>
  <img
    class="border"
    width="600"
    src="https://agnostack.com/images/shopify-private-app-screenshot.png"
    data-canonical-src="https://agnostack.com/images/shopify-private-app-screenshot.png"
  />
</center>

<br/>

<p>
Once on the <i>Create an app</i> dialog, give your app a name (ex. <i>agnoStack Zendesk Connector</i>) and select an email address. 
</p>

<br/>

<center>
  <img
    class="border"
    width="600"
    src="https://agnostack.com/images/shopify-app-details-screenshot.png"
    data-canonical-src="https://agnostack.com/images/shopify-app-details-screenshot.png"
  />
</center>

<br/>

## CONFIGURE SHOPIFY APP SCOPES

<br/>

<p>
Once on the App Devlopment <i>Overview</i> section, click on <b>Configure Admin API scopes</b> to expand and select permissions. 
</p>

<br/>

<center>
  <img
    class="border"
    width="600"
    src="https://agnostack.com/images/shopify-admin-api-screenshot.png"
    data-canonical-src="https://agnostack.com/images/shopify-admin-api-screenshot.png"
  />
</center>

<br/>
<br/>

<div id="scopes">
<p>Set the following fields to <i>Read and write</i>:</p>
<ul>
<li><b>Customers</b></li>
<li><b>Draft orders</b></li>
<li><b>Gift cards</b></li>
<li><b>Order editing</b></li>
<li><b>Orders</b></li>
<li><b>Returns</b></li>
<li><b>Store credit account transactions</b></li>
</ul>

<br/>

<p>Set the following fields to <i>Read access</i>:</p>
<ul>
<li><b>Assigned fulfillment orders</b></li>
<li><b>Discounts</b></li>
<li><b>Inventory</b></li>
<li><b>Locations</b></li>
<li><b>Merchant-managed fulfillment orders</b></li>
<li><b>Product listings</b></li>
<li><b>Products</b></li>
<li><b>Shopify Markets</b></li>
<li><b>Store credit accounts</b></li>
<li><b>Themes</b></li>
<li><b>Third-party fulfillment orders</b></li>
</ul>
</div>

<br/>
<br/>

<p>
Leave all other fields as their default values. <i>NOTE: there should be a total of <b>24 Selected</b> scopes.</i>
</p>

<br/>

<center>
  <img
    width="800"
    class="mobile border"
    alt="Shopify Admin API Permissions"
    title="Shopify Admin API Permissions"
    src="https://agnostack.com/images/shopify-admin-api-permissions-screenshots.png"
    data-canonical-src="https://agnostack.com/images/shopify-admin-api-permissions-screenshots.png"
  />
</center>

<br/>

<p>
Click <b>Save</b> and then click <b>Install app</b> in the top right-hand corner of the page and then click the <b>Install</b> button on the confirmation pop up.
</p>

<br/>

<p>
You will be presented with your credentials. You will need to obtain your <b>API Access Token</b>.
</p>

<br/>

<center>
  <img
    class="border"
    width="600"
    src="https://agnostack.com/images/shopify-configuration-api-credentials-screenshot.png"
    data-canonical-src="https://agnostack.com/images/shopify-configuration-api-credentials-screenshot.png"
  />
</center>
