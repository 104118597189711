---
title: "Configure Stripe"
---

```
(NOTE: You can return to this Configuration panel at any time (as an admin) to modify your settings or subscription settings)
```

<br/>

<p>
<Anchor href="https://stripe.com/">Stripe</Anchor><span>&nbsp;can be connected to Zendesk as a Commerce Provider through the agnoStack plugin and supports using a built-in Shipping Provider, making use of the shipping configuration you've already set up in WooCommerce. It currently supports up to our Premium subscription tier.</span>
</p>

<br/>

<center>
  <img
    class="border perspective sidebar"
    src="https://agnostack.com/images/stripe-configuration-screenshot.png"
    data-canonical-src="https://agnostack.com/images/stripe-configuration-screenshot.png"
    width="300"
  />
</center>

<br/>
<br/>

## Stripe Dashboard

<p>
If you already have API credentials mentioned below, you may enter them in the agnoStack configuration screen. Otherwise, you can obtain these values by performing the following steps:
</p>

<br/>

<p>
From your Stripe dashboard, navigate to the <i>API Keys</i> screen by selecting <b>Developers</b> > <b>API Keys</b> from the left-hand navigation menu.
</p>

<br/>

<p>
You will then be presented with your credentials. You will need to obtain both the <b>Public Key (i.e. Publishable Key)</b> and the <b>Secret Key</b>. Use these credentials to sign in to the agnoStack plugin.
</p>

<br/>
<center>
  <img class="border" width="500" src="https://agnostack.com/images/stripe-keys-screenshot.png" data-canonical-src="https://agnostack.com/images/stripe-keys-screenshot.png" />
</center>

<br/>
<br/>

## Shipping provider

<p>
If you would like to bring in detailed shipping information from an external provider, you can configure one in the Shipping provider section. Currently we are supporting ShipStation. You may also choose "Suggest New Provider" and select from the list or type in a provider to make a suggestion for us to implement.
</p>

<br/>
<br/>

We regularly add new Providers to the application and continually reprioritizing our Roadmap based on customer input. If you use a Gateway that you don't see listed, just let us know and we'll take that info back to the team ASAP!

<br/>
<br/>

<div class="website">
Once you've completed entering your Provider configuration data, continue on to <Anchor href="https://agnostack.com/faqs/setup-onboarding/select-a-subscription">Select a Subscription</Anchor>.
</div>
