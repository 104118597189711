/* eslint-disable react/jsx-filename-extension */
import React, { useMemo } from 'react'
import { MDXProvider } from '@mdx-js/react'
import { exists, window } from 'browser-monads-ts'

import { capitalize } from '@agnostack/lib-core'

import * as ConfigurationReview from './configurationReview.mdx'
import * as ConfigureAddOns from './configureAddOns.mdx'
import * as ConfigurePermissions from './configurePermissions.mdx'
import * as ConfigureSubscription from './configureSubscription.mdx'
// import * as ConfigureSubscriptionFree from './configureSubscriptionFree.mdx' // TODO: create special free tier content that it's not very useful and may be sunsetting soon?
import * as ConfigureProviders from './configureProviders.mdx'
import * as ConfigureProvidersBigCommerce from './configureProvidersBigCommerce.mdx'
import * as ConfigureProvidersBigCommerceListingBigCommerce from './configureProvidersBigCommerceListing_BigCommerce.mdx'
import * as ConfigureProvidersShopify from './configureProvidersShopify.mdx'
import * as ConfigureProvidersShopifyListingShopify from './configureProvidersShopifyListing_Shopify.mdx'
import * as ConfigureProvidersShopifyListingShopifyPremium from './configureProvidersShopifyListing_ShopifyPremium.mdx'
import * as ConfigureProvidersShopifyListingShopifyPremiumLegacy from './configureProvidersShopifyListing_ShopifyPremium_Legacy.mdx'
import * as ConfigureProvidersCommerceTools from './configureProvidersCommerceTools.mdx'
import * as ConfigureProvidersCommerceToolsListingCommerceTools from './configureProvidersCommerceToolsListing_CommerceTools.mdx'
import * as ConfigureProvidersMagento2 from './configureProvidersMagento2.mdx'
import * as ConfigureProvidersMagento2ListingMagento2 from './configureProvidersMagento2Listing_Magento2.mdx'
import * as ConfigureProvidersElasticPath from './configureProvidersElasticPath.mdx'
import * as ConfigureProvidersMagento1 from './configureProvidersMagento1.mdx'
import * as ConfigureProvidersWooCommerce from './configureProvidersWooCommerce.mdx'
import * as ConfigureProvidersStripe from './configureProvidersStripe.mdx'

// TODO: move to shared ExternalizableAnchor???
const FallbackAnchor = ({
  href = '#',
  external,
  isExternal: _isExternal = external,
  children,
  ...props
}) => {
  const renderProps = useMemo(() => {
    let isExternal = _isExternal ?? (href?.startsWith('http') || href?.startsWith('mailto:'))

    // NOTE: intentionally not using hasWindow (utils-react) to avoid dependency
    if (exists(window) && isExternal) {
      isExternal = !href?.startsWith(window.location.origin)
    }

    return {
      href,
      ...isExternal && {
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    }
  }, [href, _isExternal])

  return (
    <a data-type="fallback-anchor" {...props} {...renderProps}>
      {children}
    </a>
  )
}

const FALLBACK_SHORTCODES = {
  Anchor: FallbackAnchor,
}

const markdownObjects = {
  ConfigurationReview,
  ConfigureAddOns,
  ConfigurePermissions,
  ConfigureSubscription,
  // ConfigureSubscriptionFree,
  ConfigureProviders,
  ConfigureProvidersBigCommerce,
  ConfigureProvidersBigCommerceListingBigCommerce,
  ConfigureProvidersShopify,
  ConfigureProvidersShopifyListingShopify,
  ConfigureProvidersShopifyListingShopifyPremium,
  ConfigureProvidersShopifyListingShopifyPremiumLegacy,
  ConfigureProvidersCommerceTools,
  ConfigureProvidersCommerceToolsListingCommerceTools,
  ConfigureProvidersMagento2,
  ConfigureProvidersMagento2ListingMagento2,
  ConfigureProvidersElasticPath,
  ConfigureProvidersMagento1,
  ConfigureProvidersWooCommerce,
  ConfigureProvidersStripe,
}

export const markdown = Object.entries(markdownObjects).reduce((_markdown, [name, value]) => {
  const markdownName = `${name}Markdown`
  const { default: Component, ...data } = value

  return {
    ..._markdown,
    [markdownName]: {
      ...data,
      component: (renderProps) => (
        <MDXProvider
          components={(components) => ({
            ...FALLBACK_SHORTCODES,
            ...components,
          })}
        >
          <Component {...renderProps} />
        </MDXProvider>
      ),
    },
  }
}, {})

export const components = Object.entries(markdown).reduce((_components, [name, { component }]) => ({
  ..._components,
  [capitalize(name)]: component,
}), {})
