---
title: "Configure Shopify Premium"
---

<br/>

<div class="border callout">
  If you have already completed the connection process, the configuration panel will automatically authorize your connection, and you can skip to the section below to <Anchor href="#configure-activate-the-shopify-premium-for-zendesk-plugin"><b>Configure/Activate the Shopify Premium for Zendesk plugin</b></Anchor>.

  <br/>
  <br/>

  <center>
    <img
      class="border"
      width="65%"
      src="https://agnostack.com/images/shopify-premium-configuration-connected.png"
      data-canonical-src="https://agnostack.com/images/shopify-premium-configuration-connected.png"
    />
  </center>
</div>

<br/>
<br/>

<p>
  To start the connection process, first enter your Shopify <b>subdomain</b> in the Store Name field. For example, if your Shopify Admin URL is <i>mystorefront.myshopify.com</i>, the value for Store Name should be entered as <i>mystorefront</i> (the Shopify Store Name will be the section of the URL after "https://" and before ".myshopify.com").
</p>

<br/>
<br/>

<center>
  <img
    class="border perspective sidebar"
    src="https://agnostack.com/images/shopify-premium-configuration-screenshot.png"
    data-canonical-src="https://agnostack.com/images/shopify-premium-configuration-screenshot.png"
    width="300"
  />
</center>

<br/>
<br/>

<p>
  Click on <b>Connect</b> to begin the connection process.
</p>

<br/>

<p>
  If you have not yet installed the app in the Shopify app store, you will be presented with the app consent screen, and click <b>Install</b>.
</p>

<br/>
<br/>

<center>
  <img
    class="border"
    width="75%"
    src="https://agnostack.com/images/premium-zendesk-shopify-configuration-install-screenshot.png"
    data-canonical-src="https://agnostack.com/images/premium-zendesk-shopify-configuration-install-screenshot.png"
  />
</center>

<br/>
<br/>

<p>
  You will then be presented with the Installation flow screen, where the agnoStack box will be displayed as Connected.  First click <b>Connect</b> in the Zendesk box, until the screen shows it as Connected, then repeat the process by clicking <b>Connect</b> on the Shopify box.
</p>

<br/>
<br/>

<center>
  <img
    class="border"
    width="75%"
    src="https://agnostack.com/images/premium-zendesk-shopify-connection-flow.png"
    data-canonical-src="https://agnostack.com/images/premium-zendesk-shopify-connection-flow.png"
  />
</center>

<br/>
<br/>

<p>
  Once all three boxes are Connected, you can click on <b>Complete Installation</b> to configure the app in Zendesk.
</p>

<br/>
<br/>

## Configure/Activate the Shopify Premium for Zendesk plugin

```
(NOTE: You can return to this Configuration panel at any time (as an admin) to modify your settings or subscription settings)
```

<br/>

<p>
  <Anchor href="https://www.shopify.com/">Shopify</Anchor><span>&nbsp;can be connected to Zendesk as a Commerce Provider through the agnoStack plugin and supports using a built-in Shipping and Payment Provider, making use of the  configuration you've already set up in Shopify.</span>
</p>

<br/>
<br/>

<p>
  Your <b>Store Name</b> is the same as your Shopify subdomain. For example, if your Shopify Admin URL is <i>mystorefront.myshopify.com</i>, the value for Store Name should be entered as <i>mystorefront</i> (the Shopify Store Name will be the section of the URL after "https://" and before ".myshopify.com").
</p>

<br/>
<br/>

<center>
  <img
    class="border perspective sidebar"
    src="https://agnostack.com/images/shopify-premium-configuration-connected-screenshot.png"
    data-canonical-src="https://agnostack.com/images/shopify-premium-configuration-connected-screenshot.png"
    width="300"
  />
</center>

<div class="website">
<br/>
<br/>
Once you've completed entering your Provider configuration data, continue on to <Anchor href="https://agnostack.com/faqs/setup-onboarding/select-a-subscription">Select a Subscription</Anchor>.
</div>
