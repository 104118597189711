---
title: "Configure Shopify"
---

```
(NOTE: You can return to this Configuration panel at any time (as an admin) to modify your settings or subscription settings)
```

<br/>

<p>
<Anchor href="https://www.shopify.com/">Shopify</Anchor><span>&nbsp;can be connected to Zendesk as a Commerce Provider through the agnoStack plugin and supports using a built-in Shipping and Payment Provider, making use of the  configuration you've already set up in Shopify.</span>
</p>

<br/>

<center>
  <img
    class="border perspective sidebar"
    src="https://agnostack.com/images/shopify-configuration-screenshot-listing.png"
    data-canonical-src="https://agnostack.com/images/shopify-configuration-screenshot-listing.png"
    width="300"
  />
</center>

<br/>
<br/>
