---
title: "Confirm Configuration"
---

## You're almost there!

agnoStack is going to make your agents’ jobs easier, your customers happier, and your business boomier.

<br/>

You, like us, know that <b>customer experience shouldn’t stop at checkout</b>.

<br/>

So, if for some reason you're having trouble with your setup (or agnoStack properly isn’t working any time), or if you have any questions or ideas for improvement, we’re always here to chat: <a target="_blank" href="mailto:support@agnostack.com">support@agnostack.com</a>.

<br/>

Or better yet, if you’ve got 15 minutes, I’d love to personally walk you through the product and show you just how much agnoStack can do. You can book here: <Anchor href="https://agnostack.com/book-a-meeting">https://agnostack.com/book-a-meeting</Anchor>.

<br/>
<br/>

On behalf of your future happier customers, thanks for signing up!

<div class="signature">
  <div>
    <img class="avatar" width="80px" alt="Claire Jones: Client Support Lead." title="Claire Jones: Client Support Lead."
    data-canonical-src="https://agnostack.com/images/ClaireJones.png" src="https://agnostack.com/images/ClaireJones.png"/>
  </div>
  <div>
    <b>Claire Jones</b><br/>
    Client Support Lead.
  </div>
</div>
