---
title: "Choose Optional Add-Ons"
---

Depending on your selected Providers and agnoStack subscription plan, you may be presented with an additional configuration step to <b>Configure Add-Ons</b>. In this section, additional items available for your setup can be configured including <Anchor href="#add-on-providers">Add-On Providers</Anchor> as well as options <Anchor href="#add-on-feature-sets">Add-On Feature Sets</Anchor>.

<br/>
<br/>

## Add-On Providers

<div class="columns">
  <div>
    <p>
      You can optionally configure any available <b>Add-On Providers</b> to extend the capabilities of your agnoStack setup by adding access to additional data for your support agents!
    </p>
    <br />
    <p>
      Add-On Providers can help give your support agents an enhanced view of activity related to Customers, Orders, Shipments and more. Additional information can be surfaced across multiple areas of the agnoStack platform - including our <b>Customer Insights</b> panel and new <b>Advanced Customer Timeline</b>, as well as our <b>Advanced Order Timeline</b> and <b>Order Insights</b> panel. They can also enable access to additional <b>Advanced Data</b> across Customer and Order metadata panels.
    </p>
    <br />
    <p>
      This includes additional information such as:
    </p>
    <ul>
      <li>Recurring/Subscription Orders</li>
      <li>Shipping Insights</li>
      <li>CRM</li>
      <li>Loyalty</li>
      <li>Ratings &amp; Reviews</li>
      <li>Marketing Automation</li>
      <li>Enhanced "AI for Commerce"</li>
      <li>Custom Data</li>
      <li>...and much more coming soon!</li>
    </ul>
    <br />
    <p>
      We continue to add additional new Providers to the platform on a regular basis with each <Anchor href="https://agnostack.com/release-notes">new release</Anchor>. View the full list of all available Add-On Providers on our <Anchor href="https://partners.agnostack.com">integrations page</Anchor>.
    </p>
    <br/>
    <p>
      Configuring an Add-On Provider depends specifically on each integration, but typically the setup is straightforward and usually involves obtaining an API key(s) from your Provider's admin portal.
    </p>
    <br/>
    <p>
      Once you have obtained the necessary credentials, you may enter them during the configuration step and proceed on with the rest of the activation.
    </p>
    <br/>
    <p>
      For inquires or suggestions related to Add-On solutions or for support related to setting up your Add-On Provider, please reach out to <a target="_blank" href="mailto:support@agnostack.com?subject=Add-On%20Providers">support@agnostack.com</a> 
    </p>
  </div>
  <div class="third right">
    <img	
      class="border perspective sidebar"
      alt="Add-On Configurations"
      title="Add-On Configurations"
      src="https://agnostack.com/images/add-on-configuration-screenshot.png"
      data-canonical-src="https://agnostack.com/images/add-on-configuration-screenshot.png"
    />
  </div>
</div>

<br/>
<br/>

## Add-On Feature Sets

Configuring <b>Add-On Feature Sets</b> allows you to unlock flagship features that are bundled within certain <Anchor href="https://agnostack.com/pricing/#tiers">subscription tiers</Anchor> to now have them available to add on to your plan. Any Add-On Feature Sets purchased become available immediately after purchase to all agents using your agnoStack account.

<br/>

**Macros Package**

Our new Add-On <b>Macros Package</b> enables your agents to access our <Anchor href="https://agnostack.com/release-notes/release-number/3-6-0#macros---now-available-as-an-add-on-package">Advanced Macros</Anchor> and <Anchor href="https://agnostack.com/release-notes/release-number/2-2-0#macro-snippets--advanced-macros">Macro Snippets</Anchor> functionality. These features provide one-click personalized responses to drastically reduce ticket response time and help your agents improve your overall customer <i>post-purchase experience</i>. Create, save, and manage templated responses containing dynamic data sourced from your Commerce, Shipping and Payment platforms.

<br/>

<div class="image-group">
  <img	
    class="border"
    height="200px"
    alt="Macros Package Configuration"
    title="Macros Package Configuration"
    src="https://agnostack.com/images/configuration-add-on-macros-package.png"
    data-canonical-src="https://agnostack.com/images/configuration-add-on-macros-package.png"
  />
  <img	
    class="border"	
    height="200px"
    alt="Advanced Macros"
    title="Advanced Macros"
    src="https://agnostack.com/images/features-macros-advanced.png"
    data-canonical-src="https://agnostack.com/images/features-macros-advanced.png"
  />
</div>

<br/>

Agents can save response templates to your company's most common customer inquiries by crafting messages directly within the macros editor and see real-time population of shipping, payment and commerce data directly within there replaced (in much the same way you can leverage <Anchor href="https://support.zendesk.com/hc/en-us/articles/203662116-Using-placeholders">Zendesk's built-in ticket placeholders</Anchor>)!

<br/>

**Reminders Package**

Never forget to get back to a customer! You can now enable our new <Anchor href="https://agnostack.com/release-notes/release-number/3-7-0#advanced-reminders">Advanced Reminders</Anchor> functionality via the Add-On <b>Reminders Package</b> for customers across all Tiers.

<br/>

<div class="image-group">
  <img
    class="border third"
    alt="Advanced Reminders"
    title="Advanced Reminders"
    src="https://agnostack.com/images/features-order-reminders.png"
    data-canonical-src="https://agnostack.com/images/features-order-reminders.png"
  />
  <img
    class="border third"
    alt="Advanced Reminders Notifcation"
    title="Advanced Reminders Notifcation"
    src="https://agnostack.com/images/features-order-reminders-notification.png"
    data-canonical-src="https://agnostack.com/images/features-order-reminders-notification.png"
  />
</div>

<br/>

Reminders are unique to each agent's account and allow agents to set a date, time, priority and description for a reminder to ensure you never forget to back to a customer. When a deadline for a Reminder is due, you will receive a notification within the agnoStack plugin to remember to follow up. Agents can take action directly from the notification to quickly access the customer's order data. You can change, update, or delete a Reminder any time directly from the Order Details screen.

<br/>

**Watchlist Package**

Agents can save and unsave orders to a custom Watchlist directly from any Order Detail screen! Allow your support team team to quickly create and access their own personalized dashboard of orders across tickets to keep an eye on. You can now enable our new <Anchor href="https://agnostack.com/release-notes/release-number/4-0-0#orders-watchlist">Saved Order Watchlist</Anchor> functionality via the Add-On <b>Watchlist Package</b> for customers across all Tiers.

<br/>

**Multi-Configuration**

Our new "Multi-Config" feature allows you to configure up to five integrations and instantly search for orders and customers across them all at once. The Orders List and Customers List view will now display data across all configurations and contain a simple icon to help identify which platform each record relates to. Customers using multiple configurations will also now see a new confirmation screen when placing a New Order to confirm which configuration to use for the purchase. You can now enable our new <Anchor href="https://agnostack.com/release-notes/release-number/4-0-0#multiple-configurations">Multiple Store/Platform Configuration</Anchor> functionality via the Add-On <b>Multi-Configuration</b> for customers across all Tiers.

<div class="website">
<br/>
<br/>
Once you've completed configuring Add-Ons, continue on to <Anchor href="https://agnostack.com/faqs/setup-onboarding/activate-your-configuration">Activate Your Configuration</Anchor>.
</div>
