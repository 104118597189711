---
title: "Configure Magento 2"
---

```
(NOTE: You can return to this Configuration panel at any time (as an admin) to modify your settings or subscription settings)
```

<br/>

<span>[Magento 2](https://business.adobe.com/products/magento/magento-commerce.html) - as well as support for <Anchor href="https://www.adobe.com/commerce/magento/enterprise.html">Adobe Commerce Cloud</Anchor> - can be connected to Zendesk as a Commerce Provider through the agnoStack plugin and supports using a built-in Shipping and Payment Provider, making use of the configuration you've already set up in Magento.</span>

<br/>
<br/>

<center>
  <img class="border perspective sidebar" src="https://agnostack.com/images/magento2-configuration-screenshot.png" data-canonical-src="https://agnostack.com/images/magento2-configuration-screenshot.png" width="300" />
</center>

<br/>
<br/>

## Store URL

Please enter the URL where your Magento 2 store is publicly accessible (starting with <i>https://</i>). This should not contain any extra values such as <i>/index.php</i> or similar - it is typically just as simple as **https://&laquo;YOUR DOMAIN&raquo;.com**.

<br/>
<br/>

## Magento 2 Dashboard

<p>
  If you already have API credentials mentioned below, you may enter them in the agnoStack configuration screen, otherwise you can obtain these values by performing the following steps:
</p>

<br/>

<p>
  From your Magento 2 admin dashboard, navigate to the <i>Integrations</i> page by selecting <b>System</b> > <b>Integrations</b> from the menu on the dashboard.
</p>

<br/>

<p>
  Once on the <i>Integrations</i> screen, click on <b>Add New Integration</b><span> (You may use any desired name for your new Integration).</span>
</p>

<br/>
<br/>
<br/>

## Access Token

<p>
  In order for the agnoStack plugin to gain access to your Integration, you will need to configure the proper API permissions.
</p>

<br/>

```
(NOTE: after activation, if your plugin displays a message containing "fail-permissions-m2", it likely means your API OAuth Scopes were configured incorrectly.)
```

<br/>
<br/>

<p>
  While creating your Integration, click on the API section and set the appropriate resources permissions.  You may either select 'All' from the Resource Access dropdown, or give your Integration Custom permissions.  The following settings are recommended:
</p>

<br/>

<center>
  <img
    class="border"
    width="300"
    src="https://agnostack.com/images/magento2-permissions-screenshot.png"
    data-canonical-src="https://agnostack.com/images/magento2-permissions-screenshot.png"
  />
</center>

<br/>

<p>
  Save your new new Integration, and you will be taken back to the <i>Integrations</i> listing page.  Find your new integration and click on the <b>Activate</b> link in the third column.
</p>

<br/>

<center>
  <img
    class="border"
    width="700"
    src="https://agnostack.com/images/magento2-integrations-screenshot.png"
    data-canonical-src="https://agnostack.com/images/magento2-integrations-screenshot.png"
  />
</center>

<br/>

<p>
  On the approval pop-up, click on <i>Allow</i> to activate your integration.  You will be presented with your credentials where you can obtain the <b>Access Token</b> needed for the agnoStack configuration screen.
</p>

<br/>
<br/>

<center>
  <img
    class="border"
    width="700"
    src="https://agnostack.com/images/magento2-credentials-screenshot.png"
    data-canonical-src="https://agnostack.com/images/magento2-credentials-screenshot.png"
  />
</center>

<br/>
<br/>

## Default Payment Provider

<p>
Choose the Payment Provider that you use within your Magento 2 configuration.  We currently support Braintree, Authorize.net, as well as Stripe. If you use a Payment Provider that you don't see listed, just let us know and we'll take that info back to the team to influence the roadmap!
</p>

<br/>

<p>
By selecting "<i>Other Payment Method</i>" you will not be able to access full Checkout functionality, but will still be able to use the rest of the agnoStack plugin.
</p>

<br/>

<center>
  <img class="border perspective sidebar" src="https://agnostack.com/images/magento2-configuration-defaultpayment-screenshot.png" data-canonical-src="https://agnostack.com/images/magento2-configuration-defaultpayment-screenshot.png" width="300"/>
</center>

<br/>
<br/>

```
(NOTE: Depending on your selection for Default Payment Method, you may be prompted to also enter credentials for your selected Payment Provider.)
```

<br/>
<br/>

## Admin URI (OPTIONAL)

Admin URI is the text located after the Store URL for your Magento admin dashboard. Typically this resembles "<i>**admin_123456**</i>". If, by chance, you have altered the Admin URI for your Magento 2 store within the <i>Stores</i> > <i>Settings</i> > <i>Configuration</i> > <i>Advanced</i> > <i>Admin</i> > <i>Admin Base URL</i> configuration section of the admin dashboard, it may not match that default pattern of "<i>admin_123456</i>".

<br />

Either way, you should be able to see the Admin URI directly in the browser URL when accessing your Magento 2 admin dashboard.

<br/>

<center>
  <img class="border" src="https://agnostack.com/images/magento2-dashboard-admin-uri.png" data-canonical-src="https://agnostack.com/images/magento2-dashboard-admin-uri.png" />
</center>

<br/>

```
(NOTE: The Admin URI field can be entered to enable orders as well as products details within an order to link through to your Magento admin dashboard.)
```

<br/>
<br/>

## Store Code (OPTIONAL)

Store Code is optional, but if you are running multiple stores within your Magento 2 instance, you can obtain the store code for the store that you wish to use from the Magento admin dashboard under **Stores** > **All Stores**.

<br/>
The "<i>Store Code</i>" is set up after you choose "<i>Create Website</i>" within this page, and should be available if you're running multiple Magento 2 Stores (ie. Websites) on the same server.  The value for "<i>Store Code</i>" is listed in the "<i>Store View</i>" column on this screen and you must use the value from "<i>(Code: value)</i>".  In this example, it would be "<i>alternate</i>".

<br/>
<br/>

<center>
  <img class="border" src="https://agnostack.com/images/magento2-admin-store-code-screenshot.png" data-canonical-src="https://agnostack.com/images/magento2-admin-store-code-screenshot.png" />
</center>

<br/>

```
(NOTE: If you need to configure across multiple stores at once you can enter All, *, or a comma-separated list of store codes into the input field. If nothing is entered in the field it will automatically point to your default store.)
```

<br/>

<div class="website">
  Once you've completed entering your Provider configuration data, continue on to <Anchor href="https://agnostack.com/faqs/setup-onboarding/select-a-subscription">Select a Subscription</Anchor>.
</div>
