---
title: "Configure CommerceTools"
---

```
(NOTE: You can return to this Configuration panel at any time (as an admin) to modify your settings or subscription settings)
```

<br/>

<p>
<Anchor href="https://commercetools.com/">CommerceTools</Anchor><span>&nbsp;can be connected to Zendesk as a Commerce Provider through the agnoStack plugin and supports using a built-in Shipping Provider, making use of the shipping configuration you've already set up in CommerceTools. It currently supports up to our Premium subscription tier.</span>
</p>

<br/>

<center>
  <img
    class="border perspective sidebar"
    src="https://agnostack.com/images/commercetools-configuration-screenshot.png"
    data-canonical-src="https://agnostack.com/images/commercetools-configuration-screenshot.png"
    width="300"
  />
</center>

<br/>
<br/>

## CommerceTools Dashboard

<p>
If you already have API credentials mentioned below, you may enter them in the agnoStack configuration screen. Otherwise, you can obtain these values by performing the following steps:
</p>

<br/>

<p>
From your CommerceTools store dashboard, navigate to the <i>Developer Settings</i> screen by selecting <b>Settings</b> > <b>Developer Settings</b> from the left-hand navigation menu.
</p>

<br/>

<p>
Once on the <i>Developer Settings</i> screen, click on <b>Create new API client</b> located in the top right.
</p>

<br/>

<center>
  <img class="border" width="500" src="https://agnostack.com/images/commercetools-create-new-api-client-screenshot.png" data-canonical-src="https://agnostack.com/images/commercetools-create-new-api-client-screenshot.png" />
</center>

<br/>

<p>
Enter a Name for the new API client and ensure the following scopes have proper permission: (other scopes can be set to your discretion). Click <b>Create API client</b> once completed.
</p>

<br/>

<center>
  <img class="border" width="500" src="https://agnostack.com/images/commercetools-api-configuration-screenshot.png" data-canonical-src="https://agnostack.com/images/commercetools-api-configuration-screenshot.png" />
</center>

<br/>

<p>
You will be presented with your credentials.  You will need to obtain <b>project_key</b>, <b>client_id</b>, <b>secret</b>, <b>API URL</b>, and <b>AUTH URL</b>. Save these credentials as this info will not be available once you have moved on from this page. Use these credentials to sign in to the agnoStack plugin.
</p>

<br/>
<center>
  <img class="border" width="500" src="https://agnostack.com/images/commercetools-keys-screenshot.png" data-canonical-src="https://agnostack.com/images/commercetools-keys-screenshot.png" />
</center>

<div class="website">
<br/>
<br/>
Once you've completed entering your Provider configuration data, continue on to <Anchor href="https://agnostack.com/faqs/setup-onboarding/select-a-subscription">Select a Subscription</Anchor>.
</div>
