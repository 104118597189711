---
title: "Configure Magento 1"
---

<p>
  <Anchor href="https://magento.com/blog/magento-news/magento-community-edition-1.9.1-now-available-download">Magento 1</Anchor><span>&nbsp;can be connected to Zendesk as a Commerce Provider through the agnoStack plugin with basic support for our Free as well as Starter subscription tiers. For customers running running Magento 1 version 3.0.0 (or higher), our Starter as well as Professional subscription tiers are now supported.</span>
</p>

<br/>

The new agnoStack "`M1 plugin`" continues to make use of the Zendesk developed [Magento 1(M1) Extension](https://github.com/zendesk/magento_extension) to provide the connection between our app for Magento M1 and your Magento instance.

<br/>

<span>
As of September 7, 2020 agnoStack has officially <Anchor href="https://agnostack.com/press-releases/agno-stack-takes-over-zendesk-magento-1-extension">taken over support of Extension</Anchor> from <Anchor href="https://www.zendesk.com">Zendesk</Anchor> and has just introduced a new 3.0.0 version that fixes many defects and introduces significant additional functionality. If you are not yet running version 3.0.0 (or higher) of the Extension, please upgrade it today at: <Anchor href="https://github.com/agnostack/magento_extension/releases">https://github.com/agnostack/magento_extension/releases</Anchor> to continue to receiving ongoing support.
</span>

<br/>
<br/>

## Ensure you have installed the latest version of the Extension

To check to see if you have the latest version installed, log into your Magento Admin Panel and access the Extension dashboard page by selecting **Zendesk** > **Dashboard**. Below the left-hand menu, if you are running version 3.0.0 and above, your version will be listed.  If your version is not listed, please <Anchor href="https://support.zendesk.com/hc/en-us/articles/203660046-Magento-Integration-Installing-the-Zendesk-extension-for-Magento">upgrade your Extension</Anchor>.

<br/>

<center>
  <img
    class="border"
    width="500"
    src="https://agnostack.com/images/magento1-extension-version-screenshot.png"
    data-canonical-src="https://agnostack.com/images/magento1-extension-version-screenshot.png"
  />
</center>

<br/>
<br/>

## Configure The Plugin

After you've installed the plugin inside of your Zendesk instance, open up the Support Center and click on the agnoStack icon in the Zendesk sidebar navigation panel on the left-hand side of the screen.

<br/>

Once in the fullscreen view of the agnoStack plugin, you will be prompted (as an admin) to Configure Your Account. This configuration panel can be accessed via the Gear icon on the right-hand navigation menu.

<br/>

<center>
  <img
    class="border"
    src="https://agnostack.com/images/agnostack-commerce-fullscreen-screenshot.png"
    data-canonical-src="https://agnostack.com/images/agnostack-commerce-fullscreen-screenshot.png"
    width="75%"
  />
</center>

<br/>

```
(NOTE: You can return to this Configuration panel at any time (as an admin) to modify your settings or subscription settings)
```

<br/>

<center>
  <img class="border perspective sidebar" src="https://agnostack.com/images/magento1-configuration-screenshot.png" data-canonical-src="https://agnostack.com/images/magento1-configuration-screenshot.png" width="300" />
</center>

<br/>
<br/>

## Store URL

Please enter the URL where your Magento 1 store is publicly accessible (starting with `https://`). This should be the location where you have installed the Magento 1 Extension and should not contain any extra values such as `/index.php` or similar - it is typically just as simple as **https://&laquo;YOUR DOMAIN&raquo;.com**.

<br/>
<br/>

## API Token

The agnoStack Zendesk plugin needs to be provided with the API token provided by the Magento Extension.

<br/>

1. Log into your Magento Admin Panel and access the configuration page by selecting **System** > **Configuration** > **Zendesk**.

<br/>

<center>
  <img
    class="border"
    width="700"
    src="https://agnostack.com/images/magento1-extension-api-token-screenshot.png"
    data-canonical-src="https://agnostack.com/images/magento1-extension-api-token-screenshot.png"
  />
</center>

<br/>
<br/>

2. Under the **API Details** section, ensure that the "`API Enabled`" dropdown option is set to Yes.

<br/>
<br/>

3. Copy the value from the "`API Token`" field in the Magento Extension (above) to enter into the "`API Token`" field inside of the agnoStack plugin "`Configure/Manage Your Account`" screen. This token should be 32 characters in length.

<br/>
<br/>

## M1 Extension Version

The agnoStack plugin needs to be provided with the details around the version of the Zendesk Magento Extension you have installed. If you are running a version of the Extension prior to 3.0.0, we strongly suggest <Anchor href="https://github.com/agnostack/magento_extension/releases">upgrading to the latest version</Anchor> to fix several core issues, BUT you can still continue to use the plugin by choosing **Older Version**. If you are running 3.0.0 or higher, please select **3.0.0+** from the dropdown menu.

<br/>

<center>
  <img class="border" src="https://agnostack.com/images/magento1-configuration-apiversion-screenshot.png" data-canonical-src="https://agnostack.com/images/magento1-configuration-apiversion-screenshot.png" width="300" />
</center>

<br/>
<br/>

## Store Code (OPTIONAL)

Store Code is optional, but if you are running multiple stores within you Magento 1 instance, you can obtain the store code for the store that you wish to use from the Magento Admin panel under **System** > **Manage Stores**.

<br/>

The "`Store Code`" is set up when you create a "`New Website`" within that page initially and should be available if you're running multiple Magento 1 Stores (ie. Websites) on the same server.

<center>
  <img class="border" src="https://agnostack.com/images/magento1-admin-store-code-screenshot.png" data-canonical-src="https://agnostack.com/images/magento1-admin-store-code-screenshot.png" />
</center>

<br/>
<br/>

```
(NOTE: If you need to use the agnoStack plugin to access multiple stores (or multiple Magento instances - or even multiple Commerce platforms/websites), you can install the plugin multiple times in Zendesk and configure each installtion pointing to a different configuration.)
```

<br/>
<br/>

## Troubleshooting Zendesk Magento 1 Extension

If you're having any trouble setting up or running the app, please see our [Magento 1 Troubleshooting Guide](https://agnostack.com/faqs/magento-1-q-a/troubleshoot-your-magento-1-setup).

<br/>
<br/>

<div class="website">
  Once you've completed entering your Provider configuration data, continue on to <Anchor href="https://agnostack.com/faqs/setup-onboarding/select-a-subscription">Select a Subscription</Anchor>.
</div>

Our Magento 1 Provider is available today for our Starter and Professional tiers, providing core functionality needed to support most eCommerce businesses, as well as a Free tier with limited/basic funcationality matching the previous "Zendesk M1 plugin".

<br/>

We will continue to explore adding our other tiers to our Magento 1 integration, prioritized based on client interest. Contact us any time at <a target="_blank" href="mailto:support@agnostack.com?subject=Magento%201">support@agnostack.com</a> to learn more or share any input.
